import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';
import Breadcrumb from '@/components/breadcrumb/breadcrumb';
import Toolbar from '@/components/toolbar/toolbar';

export default {
    name: "news",
    data(){
        return{
            product_list: null,
            chk3:false,
            list_news: [],
            store_news: [],
            month_th: [
                { id: "01", name: "ม.ค.", name_en:"Jan"},
                { id: "02", name: "ก.พ.", name_en:"Feb"},
                { id: "03", name: "มี.ค.", name_en:"March"},
                { id: "04", name: "เม.ย.", name_en:"April"},
                { id: "05", name: "พ.ค.", name_en:"May"},
                { id: "06", name: "มิ.ย.", name_en:"June"},
                { id: "07", name: "ก.ค.", name_en:"July"},
                { id: "08", name: "ส.ค.", name_en:"Aug"},
                { id: "09", name: "ก.ย.", name_en:"Sept"},
                { id: "10", name: "ต.ค.", name_en:"Oct"},
                { id: "11", name: "พ.ย.", name_en:"Nov"},
                { id: "12", name: "ธ.ค.", name_en:"Dec"},
            ],
            filter_news: 0,
            baseFront: this.$baseFront
        }
    },
    watch: {
        filter_news(value) {
            this.sort_news(value)
        }
    },
    methods: {
        sort_news(value) {
            if(value == 0) {
                this.list_news = this.store_news
            } else if(value == 1) {
                this.list_news = this.store_news.filter(i=>i.post_date.split("-")[0] == "2023")
            } else if(value == 2) {
                this.list_news = this.store_news.filter(i=>i.post_date.split("-")[0] == "2022")
            }
        },
        FormatDate(value) {
            let data = value.split("T")
            let create_date = data[0].split("-")
            let day = create_date[2];
            let month = this.month_th.find(i => i.id == create_date[1])[(this.$lang == 'th' ? 'name':'name_en')]
            let year = parseInt(create_date[0]) + (this.$lang == 'th' ? 543:null);
            let date = day + ' ' + month + ' ' + year;
            return date;
        },
        gotoDetail(id) {
            this.$router.push(`content/${id}`)
        },
        async fetchnews() {
            await this.$axios.get("/api/show/news")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.list_news = response.data.data.sort((a, b) => a.sort - b.sort)
                        this.store_news = response.data.data.sort((a, b) => a.sort - b.sort)
                    }
                })
        },
    },
    async mounted () {
        await this.fetchnews()
        document.title = "AGC - News";
        AOS.init();
    },
    components: {
        Footer,Header,Breadcrumb,Toolbar
    }
};