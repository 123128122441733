import { render, staticRenderFns } from "./news.component.html?vue&type=template&id=749906b6&scoped=true&"
import script from "./news.component.js?vue&type=script&lang=js&"
export * from "./news.component.js?vue&type=script&lang=js&"
import style0 from "./news.component.scss?vue&type=style&index=0&id=749906b6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "749906b6",
  null
  
)

export default component.exports